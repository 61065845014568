import React from "react";
import ReactDOM from "react-dom/client";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import MUIToolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import Animation from "./lottee.json";
import Lottie from "react-lottie";
import { Box, StyledEngineProvider } from "@mui/system";
import LessonpalIconBig from "./logo.svg";
import LessonpalIconSmall from "./icon.svg";
import theme from "./theme";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: "4px",
  },
  toolbar: {
    borderBottom: "1px solid #E0E0E0",
  },
  logo: {
    height: 24,
    cursor: "pointer",
    marginRight: "16px",
    [theme.breakpoints.down("tab")]: {
      height: 32,
    },
  },
}));

function Page(props) {
  const s = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const breakpoint = useMediaQuery(theme.breakpoints.down("tab"));

  return (
    <React.Fragment>
      <AppBar color="inherit" position="fixed" elevation={0}>
        <MUIToolbar color="black" className={s.toolbar}>
          <img
            src={breakpoint ? LessonpalIconSmall : LessonpalIconBig}
            alt="Lessonpal"
            className={s.logo}
          />
        </MUIToolbar>
      </AppBar>
      <div className={s.container}>
        <Box sx={{ pt: "128px" }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: Animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={isMobileView ? 240 : 333}
            width={isMobileView ? 240 : 333}
          />
        </Box>
        <Box sx={{ mt: "42px", textAlign: "center", pl: "16px", pr: "16px" }}>
          <Typography variant="h4">Preparing to launch!</Typography>
          <Typography variant="body1">
            Lessonpal is under maintenance right now. Please check back soon.
          </Typography>
        </Box>
      </div>
    </React.Fragment>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Page />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
